@import "bourbon/bourbon";
@import "neat/neat";
@import "base/base";
@import url("https://fonts.googleapis.com/css?family=Roboto");

@mixin shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.body,
html {
  font-family: "Roboto", "Arial", sans-serif;
  background-color: #eee;
  h1,
  h2,
  p {
    color: #333;
  }
  .page-header {
    color: blue;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    padding: 1.5em;
    h1 {
      font-size: 4em;
      margin-bottom: 0.2em;
      flex-basis: 100%;
    }
    p {
      font-size: 1.1em;
      margin: 0 0.5em;
    }
  }
}

.links {
  max-width: 512px;
  margin: 0.5em auto 1em;
  display: flex;
  align-content: center;
  justify-content: space-around;
  li {
    text-align: center;
  }
}
article {
  @include shadow;
  text-align: left !important;
  max-width: 800px;
  background: white;
  padding: 2em;
  margin: 1em auto;
  header {
    h2 {
      font-size: 2em;
      margin-bottom: 0.2em;
      flex-basis: 100%;
    }
    i {
      margin-left: 0.5em;
    }
    p {
      color: #888;
      font-size: 0.9em;
    }
    p + p {
      margin-left: 0.5em;
    }
    padding: 0;
    color: #888;
    display: flex;
    flex-wrap: wrap;
  }
  p {
    code {
      background-color: rgb(228, 228, 228);
      padding: 0.2rem;
    }
  }
}

section {
  padding: 2em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.mde-preview-content {
  background: white;
}

.game {
  font-size: 2em;
  display: inline;
  span,
  input {
    display: inline;
    align-self: center;
  }
  input {
    width: 5em;
    margin-right: 1em;
  }
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

footer {
  font-size: 0.8em;
  ul {
    padding-top: 2em;
    display: flex;
    justify-content: flex-start;
    li {
      margin-right: 0.5em;
    }
  }
}

.mde-tabs {
  button {
    color: white;
    background: blue!important;
  }

}

form {
  width: 100%;
  max-width: 1000px;
  textarea {
    min-height: 400px;
  }
}
.mde-tabs {
  button {
    background-color: "blue !important";
  }
}

.work {
  position: fixed;
  bottom: 0;
  left: 0;
  background: black;
  color: white;
  font-family: monospace;
  max-width: 250px;
  padding: 1em;
}

.not-found {
  display: flex;
  flex-direction: column;
  h1,
  h2,
  p,
  a {
    text-align: center;
  }
  h1 {
    font-size: 3em;
  }
  img {
    display: block;
    margin: 1em auto;
  }
}

.right-menu {
  @include shadow;
  max-width: 25%;
  position: fixed;
  right: 0;
  top: 0;
  padding: 5em 2em;
  background: white;
  height: 100vh;
}

nav {
  z-index: 1000;
  @include shadow;
  background: white;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  direction: row;
  height: 3em;
  p,
  a {
    margin: 0 1em;
  }
}

.nav-margin {
  margin-top: 5em;
}

.auth-button {
  @include shadow;
  position: fixed;
  right: 1em;
  bottom: 1em;
}

.checkbox {
  margin: 1em 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  direction: row;
  p,
  a,
  label,
  input {
    margin: 0 0.5em 0 0;
  }
}

.admin-bar {
  @include shadow();
  color: black;
  background: white;
  display: fixed;
  position: fixed;
  padding: 10px;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.post-actions {
  width: 500px;
  background: white;
  padding: 1em;
  margin:1em;
  @include shadow;
  display: flex;
  align-items: center;
  justify-content: space-between;
}



.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: blue;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-container {
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
}